<template>
    <section>
        <span v-if="showDrop" class="upload" @dragover.prevent @drop.prevent>
            <b-form-text>{{ $t('FILES')}}: {{files.length}}</b-form-text>
            <div ref="drag" class="fileselect" :class="{over: isOver}">
                <b-jumbotron @drop="dragFile" class="mb-0">
                    <i class="fa-light fa-arrow-up-from-bracket"></i><br />{{ $t('DRAG_DROP_FILES')}}          
                    <span @click="showUpload" class="clickable green"><i class="fal fa-plus"></i> {{ $t("ADD_FILE") }} </span>
                    <b-form-text v-if="maxSize">
                        {{ $t("ATTACHMENTS_SIZE_LIMIT", { filesize: maxSize + "" }) }}
                    </b-form-text>      
                    <b-form-text v-if="maxSizePerFile">
                        {{ $t("ATTACHMENTS_PER_SIZE_LIMIT", { filesize: maxSizePerFile + "" }) }}
                    </b-form-text>    
                </b-jumbotron>
            </div>
            <input type="file" multiple v-if="fileType == ''"  />
            <input type="file" multiple v-if="fileType != ''" :accept="fileType" />
        </span>
        <span v-if="!showDrop" class="upload">
            <span @click="showUpload"><slot></slot></span>
            <input type="file" ref="filefocus" v-if="fileType == ''" />
            <input type="file" ref="filefocus" v-if="fileType != ''" :accept="fileType" />
            <b-form-text v-if="maxSize">
                {{ $t("ATTACHMENTS_SIZE_LIMIT", { filesize: maxSize + "" }) }}
            </b-form-text>                
            <b-form-text v-if="maxSizePerFile">
                {{ $t("ATTACHMENTS_PER_SIZE_LIMIT", { filesize: maxSizePerFile + "" }) }}
              </b-form-text>
        </span>
    </section>
</template>
<script>
export default {
    props: {
        value: Array,        
        fileType: {
            default: "",
            type: String,
        },
        maxSize: {
            type: [String, Number],
            default: ""
        },
        maxSizePerFile: {
            type: [String, Number],
            default: ""
        },
        showDrop: {
            type: Boolean,
            validator(value) {
                return value != undefined;
            },
        }
    },    
    data() {
        return {
            isOver: false,
            isFocus: false,
            items: [],
            files: [],
            input: null
        };        
    },
    methods: {
        dragFile(e) {
            let files = e.dataTransfer.files;
            for (let file of files) {
                if(this.checkFileBeforeAdding(file)){
                    this.files.push(file);
                }
            }
            this.isOver = false;
            this.input.value = null;
        },
        checkFileBeforeAdding(checkFile){
            for (let file of this.files) {
                if(checkFile.name == file.name)
                {
                    return false;
                }
            }
            return true;
        },
        onFileSelection() {
            for (let file of this.input.files) {
                if(this.checkFileBeforeAdding(file)){
                    this.files.push(file);
                }
            }
            this.isOver = false;
            this.input.value = null;
        },
        removeFile(index) {
            this.files.splice(index, 1);
        },
        showUpload() {
            const event = new MouseEvent('click', {
                'view': window,
                'bubbles': false,
                'cancelable': true
            });
            this.input.dispatchEvent(event);
        }
    },
    watch: {
        files(files) {
            this.$emit('input', files);
            this.$emit('added');
        }
    },
    mounted() {
        this.input = this.$el.querySelector('input[type=file]');
        this.input.addEventListener('change', () => this.onFileSelection())
        this.input.style.display = 'none'
        this.input.setAttribute('multiple', 'multiple');
        if (this.value) {
            this.files = this.value
        } else {
            this.$emit('input', [])
        }
        if(this.showDrop)
        {
            this.$refs.drag.addEventListener("dragover", () => { this.isOver = true; });
            this.$refs.drag.addEventListener("dragleave", () => { this.isOver = false; }); 
        }
    },

}
</script>
<style>
</style>