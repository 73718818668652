<template>
  <span>
    <div class="attachment">
      <div class="icon"><i class="fal fa-file"></i></div>
      <div class="information">
        <div class="name" v-b-tooltip.hover :title="info.name">{{ info.name }}</div>
        <div class="filesize">
          {{bytesToSize(info.size)}}
        </div>
         <div class="remove">
          <i class="fal fa-trash-alt pr-2 clickable" @click="removeFile(index)"></i>
        </div>
      </div>
    </div>
  </span>
</template>
<script>
export default {
  props: ["item","index"],
  data() {
    return {
      loading: false,
      info: this.item,
      blob: null
    };
  },
  methods: {
    removeFile(index) {
      this.$emit("removeFile",index);
    }
  }
};
</script>
<style>
</style>